.notifiCard {
  --notifi-primary: #00d1ff;
  --notifi-secondary: #65b1cf;
  --notifi-input: #0b0b22;
  --notifi-card: #0b0b22;
  --notifi-button: linear-gradient(74deg, #34EDB3 2.11%, #00D1FF 100%);
  --notifi-stroke: #2d2d38;
  --notifi-text: #ffffff;
  --notifi-gray: #a8a8a6;
  --notifi-verified: #93d189;
  --notifi-custom-input: #000000;
  --notifi-error: #d5828b;
  box-sizing: border-box;
  white-space: normal;
}

.notifi-card-modal {
  color: var(--notifi-text);
  height: calc(100vh - 44px);
  width: 351px;
  background-color: var(--notifi-card);
  border: 1px solid var(--notifi-stroke);
  border-radius: 8px;
}

.notifi-error-global {
  padding: 32px 20px 12px 20px;
}

.notifi-error-footer {
  background-color: var(--notifi-card);
  height: unset;
}

.notifi-error-global-error-title {
  color: var(--notifi-error);
}

.notifi-connect {
  border: unset;
  background-color: unset;
  padding: 39px 0px;
}

.notifi-connect-main {
  height: auto;
  flex-grow: unset;
  padding: unset;
  justify-content: start;
  padding-left: 32px;
}

.notifi-connect-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  padding: unset;
  line-height: 133.187%;
  letter-spacing: 0.22px;
  padding-left: 32px;
  text-align: start;
}

.notifi-connect-description {
  margin: 8px 0px 32px 32px;
  color: var(--notifi-gray);
  padding: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.187%;
  letter-spacing: 0.14px;
  text-align: start;
}

.notifi-connect-alert-list-alert {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.28px;
}

.notifi-connect-alert-icon {
  color: var(--notifi-primary);
}

.notifi-connect-button {
  border-radius: 6px;
  background: var(--notifi-button);
  color: var(--notifi-card);
  height: 43px;
  margin: 12px 28px;
  margin-top: 44px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
}

.notifi-connect-button:disabled {
  opacity: 0.5;
}

.notifi-connect-footer {
  height: unset;
}

.notifi-powered-by-notifi-content {
  color: var(--notifi-gray);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
}

.notifi-nav-header {
  border: unset;
  background-color: var(--notifi-card);
  padding: 0px;
}

.notifi-nav-header-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.16px;
}

.notifi-ftu {
  padding: 20px 0px 12px 0px;
  border: unset;
}

.notifi-ftu-target-edit {
  padding: 0px 28px;
}

.notifi-ftu-target-edit-description,
.notifi-inboxConfig-target-edit-description {
  color: var(--notifi-gray);
  font-size: 14px;
  font-weight: 600;
  margin: 28px 0px 10px 0px;
}

.notifi-target-inputs {
  padding: unset;
  gap: 13px;
}

.notifi-inbox-config-target-edit-main > .notifi-target-inputs {
  padding: 0px 28px;
}

.notifi-inbox-config-target-edit-invalid-email-warning,
.notifi-ftu-target-edit-invalid-email-warning {
  margin-left: 0px;
  margin-right: 0px;
  color: var(--notifi-error);
}

.notifi-inbox-config-target-edit-invalid-email-warning {
  margin-left: 28px;
}

.notifi-target-input-field-input-container {
  border-radius: 6px;
  background: var(--notifi-input);
  border: 1px solid var(--notifi-stroke);
}

.notifi-target-input-field-input {
  font-weight: 500;
  font-size: 16px;
  color: var(--notifi-text);
}

.notifi-target-input-field-input::placeholder {
  color: var(--notifi-gray);
  font-weight: 500;
  font-size: 16px;
}

.notifi-target-input-field-input-container.focused {
  border: 1px solid var(--notifi-primary);
}

.notifi-target-input-field-icon {
  color: var(--notifi-gray);
}

.notifi-input-separator {
  font-size: 10px;
  font-weight: 800;
  color: var(--notifi-gray);
}

.notifi-input-separator:before {
  background-color: var(--notifi-stroke);
}

.notifi-input-separator:after {
  background-color: var(--notifi-stroke);
}

.notifi-target-input-toggle-label {
  font-weight: 600;
  font-size: 14px;
}

.notifi-target-input-toggle-unavailable {
  width: 105px;
  color: var(--notifi-gray);
}

.notifi-toggle-container {
  height: 18px;
  width: 29.5px;
  flex-shrink: 0;
}

.notifi-target-input-toggle-container {
  padding: unset;
}

.notifi-toggle-slider:before {
  height: 14px;
  width: 14px;
}

.notifi-toggle-slider {
  background-color: var(--notifi-gray);
}

.notifi-toggle-input:checked + .notifi-toggle-slider {
  background-color: var(--notifi-primary);
}

.notifi-toggle-input:checked + .notifi-toggle-slider:before {
  transform: translateX(11.5px);
}

.notifi-ftu-button-container {
  border: unset;
  background-color: unset;
  padding: 0px 28px;
}

.notifi-ftu-target-edit > .notifi-ftu-button-container {
  padding: unset;
}

.notifi-ftu-alert-list-button,
.notifi-ftu-alert-edit-button,
.notifi-ftu-target-list-button,
.notifi-ftu-target-edit-button {
  border-radius: 6px;
  background: var(--notifi-button);
  color: var(--notifi-card);
  height: 43px;
  font-size: 16px;
  font-weight: 700;
  margin: 12px 0px;
}

.notifi-ftu-alert-edit-button:disabled,
.notifi-ftu-target-edit-button:disabled,
.notifi-ftu-target-list-button:disabled {
  opacity: 0.5;
}

.notifi-ftu-footer {
  background-color: unset;
  height: unset;
}

.notifi-target-list {
  padding: 12px 15px;
  padding-top: 28px;
  margin: 0px;
  gap: 8px;
}

.notifi-target-list-item {
  background-color: var(--notifi-input);
  padding: 16px;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--notifi-stroke);
}

.notifi-target-list-item-target {
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.42px;
}

.notifi-target-list-target-verify-message {
  color: var(--notifi-gray);
  font-weight: 600;
  font-size: 14px;
}

.notifi-target-list-item-target-id {
  font-weight: 800;
  font-size: 14px;
}

.notifi-inbox-config-target-list-item > .notifi-target-list-item-target-id {
  font-weight: 700;
  font-size: 14px;
}

.notifi-target-list-icon {
  color: var(--notifi-gray);
}

.notifi-target-cta {
  margin-top: 7px;
}

.notifi-target-cta-link {
  color: var(--notifi-primary);
  font-weight: 700;
  font-size: 14px;
}

.notifi-target-cta-button {
  background-color: var(--notifi-primary);
  border-radius: 6px;
  border: unset;
  min-width: 109px;
  height: 35px;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--notifi-card);
}

.notifi-target-cta-action-not-required {
  color: var(--notifi-verified);
  font-weight: 700;
  font-size: 14px;
}

/* Temp */
.notifi-target-list-item-tooltip-content > a {
  display: none;
}

.notifi-target-cta-loading-spinner {
  --notifi-color-white: var(--notifi-primary);
}

.notifi-target-list-target-confirmed-message {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  color: var(--notifi-gray);
}

.notifi-topic-list {
  margin: unset;
  padding: 12px 15px;
  gap: 8px;
}

.notifi-topic-stack-row,
.notifi-topic-row {
  background-color: var(--notifi-input);
  border-radius: 6px;
  border: 1px solid var(--notifi-stroke);
}

.notifi-topic-list-tooltip-content,
.notifi-target-list-item-tooltip-content {
  background-color: var(--notifi-card);
  border: 1px solid var(--notifi-primary);
  border-radius: 6px;
  width: 194px;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.185px;
}

.notifi-nav-header-left-cta,
.notifi-nav-header-right-cta {
  width: 18px;
  height: 18px;
  top: unset;
  left: unset;
}

.notifi-nav-header-left-cta {
  left: 24px;
}

.notifi-ftu-alert-edit-main {
  padding-top: 16px;
}

.notifi-topic-row-base {
  align-items: center;
  gap: 25px;
}

.notifi-topic-row-content {
  gap: 2px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.32px;
}

.notifi-topic-list-tooltip-icon {
  color: var(--notifi-gray);
}

.notifi-inbox {
  padding-top: 20px;
}

.notifi-history-list-target-state-banner-container {
  margin: 0px 15px;
  margin-top: 28px;
}

.notifi-history-list-main,
.notifi-history-list-main.w-banner {
  padding: unset;
  margin: 0 15px;
  margin-top: 28px;
}

.notifi-history-list-main.w-banner {
  margin-top: 12px;
}

.notifi-topic-list-tooltip-container {
  flex-shrink: 0;
}

.notifi-target-state-banner {
  background-color: unset;
}

.notifi-target-state-banner-verify.history {
  background-color: var(--notifi-stroke);
  border: 1px solid var(--notifi-stroke);
  padding: 10px;
}

.notifi-target-state-banner-verify {
  background-color: var(--notifi-input);
  border: 1px solid var(--notifi-stroke);
  border-radius: 6px;
}

.notifi-target-state-banner-verify-icon > svg {
  width: 12px;
  height: 12px;
}

.notifi-target-state-banner-verify-icon.history > svg {
  width: 16px;
  height: 16px;
}

.notifi-target-state-banner-verify-title {
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 106.687%;
  margin: 0px;
  letter-spacing: 0.16px;
}

.notifi-target-state-banner-verify-title.history {
  font-size: 14px !important;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0.14px;
}

.notifi-target-state-banner-verify-description {
  margin-top: 4px;
  color: var(--notifi-primary);
}

.notifi-target-state-banner-verify-cta.history {
  background-color: var(--notifi-primary);
  border-radius: 6px;
  display: flex;
  color: var(--notifi-card);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 77px;
}

.notifi-target-state-banner-verify-cta {
  color: var(--notifi-text);
}

.notifi-history-list-empty {
  justify-content: unset;
  gap: 0px;
}

.notifi-history-list-empty-icon > svg {
  display: none;
}

.notifi-history-list-empty-icon {
  width: 32px;
  height: 24px;
  margin: 0;
  margin-top: 42px;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyNSIgdmlld0JveD0iMCAwIDI4IDI1IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMiAxMy4wODMzTDcuNDIyNjIgMi40MjUwOUM3LjU1NTMyIDIuMTY0MjYgNy44MjMxOSAyIDguMTE1ODMgMkgyMC4zODQyQzIwLjY3NjggMiAyMC45NDQ3IDIuMTY0MjYgMjEuMDc3NCAyLjQyNTA5TDI2LjUgMTMuMDgzM00yIDEzLjA4MzNWMjIuMjIyMkMyIDIyLjY1MTggMi4zNDgyMiAyMyAyLjc3Nzc4IDIzSDI1LjcyMjJDMjYuMTUxOCAyMyAyNi41IDIyLjY1MTggMjYuNSAyMi4yMjIyVjEzLjA4MzNNMiAxMy4wODMzSDlDOS40Mjk1NSAxMy4wODMzIDkuNzc3NzggMTMuNDMxNiA5Ljc3Nzc4IDEzLjg2MTFWMTUuMjIyMkM5Ljc3Nzc4IDE1LjY1MTggMTAuMTI2IDE2IDEwLjU1NTYgMTZIMTcuNzVDMTguMTc5NiAxNiAxOC41Mjc4IDE1LjY1MTggMTguNTI3OCAxNS4yMjIyVjEzLjg2MTFDMTguNTI3OCAxMy40MzE2IDE4Ljg3NiAxMy4wODMzIDE5LjMwNTYgMTMuMDgzM0gyNi41IiBzdHJva2U9IiNBOEE4QTYiIHN0cm9rZS13aWR0aD0iMyIvPgo8L3N2Zz4=');
}

.notifi-history-list-empty-title {
  margin: 0;
  margin-top: 12px;
  margin-bottom: 7px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.16px;
}

.notifi-history-list-empty-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.187%;
  letter-spacing: 0.14px;
  color: var(--notifi-gray);
  padding: 0px;
}

.notifi-history-row-unread-indicator {
  background-color: var(--notifi-primary);
  left: 0px;
  width: 4px;
  top: 0px;
}

.notifi-history-row {
  background-color: var(--notifi-input);
  border-radius: 6px !important;
  border: 1px solid var(--notifi-stroke);
}

.notifi-history-row-title {
  font-size: 16px;
  font-weight: 700;
  gap: 15px;
  justify-content: space-between;
}

.notifi-history-row-subject {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: var(--notifi-gray);
}

.notifi-history-row-icon {
  color: var(--notifi-primary);
}

.notifi-history-row-icon > svg {
  margin-top: -3px;
}

.notifi-history-detail {
  background-color: var(--notifi-card);
}

.notifi-history-detail-main {
  padding: 0px;
  margin: 16px 24px;
}

.notifi-history-detail-subject {
  margin-bottom: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 133.187%;
  letter-spacing: 0.16px;
}

.notifi-history-detail-timestamp {
  color: var(--notifi-gray);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.187%;
  letter-spacing: 0.14px;
}

.notifi-history-detail-message {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.187%;
  letter-spacing: 0.16px;
}

.notifi-inbox-nav-tabs {
  background-color: var(--notifi-input);
  border: unset;
  border-top: 1px solid var(--notifi-stroke);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.notifi-inbox-config-topic-banner {
  margin: 0px 15px;
}

.notifi-inbox-config-topic-title {
  margin: 0px 15px;
  margin-top: 16px;
  border: unset;
  color: var(--notifi-gray);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 133.187%;
  letter-spacing: 0.14px;
}

.notifi-inbox-config-target-list-main {
  border-bottom: 1px solid var(--notifi-stroke);
}

.notifi-inbox-config-target-list-item {
  padding: 16px;
  padding-bottom: 12px;
  gap: 4px;
}

.notifi-inbox-config-target-list-item > .notifi-target-cta {
  margin: unset;
}

.notifi-inbox-config-target-list-item
  > .notifi-target-cta
  > .notifi-target-cta-action-not-required
  > svg {
  display: none;
}

.notifi-target-list-item-remove {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.36px;
  color: var(--notifi-text);
}

.notifi-inbox-config-target-edit-button:disabled {
  opacity: 0.5;
}

.notifi-inbox-config-target-edit-button,
.notifi-inbox-config-target-list-button {
  margin: 12px 28px;
  margin-bottom: 14px;
  color: var(--notifi-card);
  background: var(--notifi-button);
  min-height: 43px;
  height: 43px;
  border-radius: 6px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.08px;
  z-index: 2;
}

.notifi-inbox-config-target-edit-main {
  border-bottom: 1px solid var(--notifi-stroke);
  margin: 0;
}

.notifi-history-detail-message > p > a {
  color: var(--notifi-primary) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.187%;
  letter-spacing: 0.16px;
  text-decoration-line: underline;
}

.notifi-history-detail-message > a {
  color: var(--notifi-primary) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 133.187%;
  letter-spacing: 0.16px;
  text-decoration-line: underline;
}

.notifi-history-row-title-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  flex-grow: unset;
  font-size: 14px;
}

.notifi-history-row-timestamp {
  text-align: end;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: 500;
  color: var(--notifi-gray);
}

.notifi-spinner {
  border-top: 4px var(--notifi-card) solid;
}

.notifi-inbox-config-topic-main {
  margin: 0;
  margin-top: 28px;
}

.notifi-history-row:hover {
  border: 1px solid var(--notifi-button);
}

.notifi-inbox-nav-tabs > div:has(svg[type='bell']) {
  height: 24px;
  color: var(--notifi-gray);
}
.notifi-inbox-nav-tabs > div:has(svg[type='gear']) {
  height: 24px;
  color: var(--notifi-gray);
}

.notifi-topic-options-description {
  color: var(--notifi-gray);
  font-size: 14px;
  font-weight: 500;
}

.notifi-topic-option-custom-input-container {
  max-width: 87px;
}

.notifi-topic-option-custom-input-container,
.notifi-topic-options-item.button {
  background-color: transparent;
  border-color: var(--notifi-stroke);
  color: var(--notifi-gray);
  font-size: 16px;
  font-weight: 600;
}

.notifi-topic-option-custom-input-container.selected,
.notifi-topic-options-item.button.selected {
  border: 2px solid var(--notifi-primary);
  color: var(--notifi-text);
  font-weight: 700;
}

.notifi-topic-option-custom-input-container.selected
  > .notifi-topic-option-custom-input-suffix {
  top: 0;
  right: 1px;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
}

.notifi-topic-options-custom-input::placeholder {
  color: var(--notifi-gray);
}

.notifi-topic-option-custom-input-container
  > .notifi-topic-options-custom-input {
  padding: unset;
}

.notifi-topic-option-custom-input-container.selected
  > .notifi-topic-options-custom-input {
  padding: 0 1rem;
}
