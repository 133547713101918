* {
	box-sizing: border-box;
}

#__next {
	width: 100%;
	height: 100%;
}

#__next > div {
	height: 100%;
}

#root {
	height: 100%;
}

* {
	scroll-behavior: smooth;
}

html {
	scrollbar-width: none;
}

html,
body {
	height: 100%;
	min-height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeSpeed;
	margin: 0;
	position: relative;
	font-size: 12px;

	-ms-overflow-style: none;
	scrollbar-width: none;
	overflow: hidden;
}

::-webkit-scrollbar {
	display: none;
	width: 0;
	height: 0;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
